<template>
  <v-dialog v-model="dialog" :width="370">
    <template v-slot:activator="{ props }">
      <v-list-item class="navbar-item my-4 px-1" @click="dialog = true">
        <template v-slot:prepend>
          <v-icon icon="mdi-logout" />
        </template>

        <template v-slot:title>
          <p class="navbar-item-text">{{ $t("navbar.logout") }}</p>
        </template>
      </v-list-item>
    </template>

    <v-card class="bg-primary-500 py-2" rounded="lg">
      <v-card-text class="px-4">
        <v-container>
          <v-row class="d-flex justify-space-between mb-3">
            <h2 class="pb-2">{{ $t("logout.title") }}</h2>
            <v-icon
              icon="mdi-close"
              color="primary-100"
              size="24px"
              @click="dialog = false"
            />
          </v-row>
          <v-row class="pb-6">
            {{ $t("logout.text") }}
          </v-row>
          <v-row class="d-flex">
            <AppButton
              :text="$t('logout.cancel')"
              class="pt-4 pb-8 flex-grow-1"
              variant="outlined"
              color="primary-300"
              @click="dialog = false"
            />
            <div class="px-2"></div>
            <AppButton
              :text="$t('logout.logout')"
              class="pt-4 pb-8 flex-grow-1"
              @click="logout"
            />
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import AppButton from "@/components/atoms/AppButton.vue";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";

const router = useRouter();

const authStore = useAuthStore();
const dialog = ref(false);

const logout = () => {
  router.push("/sign-in");
  authStore.signOut();
};
</script>
<style scoped lang="scss">
.v-list-item :deep(.v-list-item__prepend .v-list-item__spacer) {
  width: 12px;
}

.v-list-group--open {
  .v-list-header {
    background-color: rgb(var(--v-theme-primary-500));
  }
  .navbar-item .navbar-item-text {
    color: white;
  }

  .active {
    width: auto;
    margin-inline: 6px;
    border-left: solid;
    border-color: rgba(var(--v-theme-tertiary-900), 1);
  }

  .active::after {
    content: " ";
    display: inline-block;
  }
}

.navbar-item {
  padding: calc(var(--v-base-spacing) * 1px) calc(var(--v-base-spacing) * 3px);
  border-radius: calc(var(--v-border-radius-root) * 1px);

  .navbar-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: rgba(var(--v-theme-primary-100), 0.7);
  }

  &:hover {
    background-color: rgb(var(--v-theme-primary-500));
    cursor: pointer;
  }
}
</style>
