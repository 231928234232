import { gql } from "@apollo/client/core";

export const UPDATE_PROFILE_STATUS_BY_PK = gql`
  mutation MyMutation($uuid: uuid = "", $status: user_status = "") {
    update_profiles_by_pk(pk_columns: { uuid: $uuid }, _set: { status: $status }) {
      uuid
    }
  }
`;

export const SUBSCRIBE_ALL_PROFILES = gql`
  subscription SubscribeAllProfiles($limit: Int = null, $offset: Int = null, $where: profiles_bool_exp = {}, $order_by: [profiles_order_by!] = {}) {
    profiles(offset: $offset, limit: $limit, where: $where, order_by: $order_by) {
      uuid
      status
      firstname
      lastname
      company_serial
      pincode
      user {
        email
        defaultRole
        phoneNumber
      }
      company {
        name
      }
    }
  }
`;

export const QUERY_PROFILES_FOR_CSV_EXPORT = (withUsersWorksite: boolean) => gql`
  query SubscribeAllProfiles(
    $limit: Int = null
    $offset: Int = null
    $where: profiles_bool_exp = {}
    $order_by: [profiles_order_by!] = {}
  ) {
    profiles(
      offset: $offset
      limit: $limit
      where: $where
      order_by: $order_by
    ) {
      uuid
      firstname
      lastname
      company_serial
      pincode
      ${
        withUsersWorksite
          ? `worksites{
        worksite{
          name
        }
      }`
          : ""
      }
      user {
        email
        phoneNumber
        lastSeen
      }
      company {
        name
      }
    }
  }
`;

export const SUBSCRIBE_PROFILES_COUNT = gql`
  subscription SubscribeProfilesCount($where: profiles_bool_exp = {}) {
    profiles_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const SUBSCRIBE_USER = gql`
  subscription SubscribeProfileByPk($uuid: uuid!, $includeWorksite: Boolean = true, $includeTos: Boolean = false) {
    profiles_by_pk(uuid: $uuid) {
      uuid
      company {
        name
      }
      company_id
      tos_accepted @include(if: $includeTos)
      firstname
      pincode
      lastname
      company_serial
      status
      avatar {
        id
        name
        mimeType
      }
      user {
        email
        defaultRole
        phoneNumber
        id
        avatarUrl
        roles {
          id
          role
        }
      }
      created_at
      worksites @include(if: $includeWorksite) {
        profil_id
        worksite {
          uuid
          name
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query SubscribeAllProfiles($uuid: uuid = "") {
    profiles_by_pk(uuid: $uuid) {
      uuid
      company {
        name
      }
      company_id
      firstname
      pincode
      lastname
      company_serial
      status
      avatar {
        id
        name
        mimeType
      }
      user {
        email
        defaultRole
        phoneNumber
        id
        avatarUrl
        roles {
          id
          role
        }
      }
      created_at
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $profilesConds: profiles_bool_exp!
    $usersConds: users_bool_exp!
    $userData: users_set_input = {}
    $profileData: profiles_set_input = {}
    $userworksites: [users_worksites_insert_input!] = {}
    $updateWorksite: Boolean = false
  ) {
    updateUsers(where: $usersConds, _set: $userData) {
      returning {
        id
      }
    }
    update_profiles(where: $profilesConds, _set: $profileData) {
      returning {
        uuid
      }
    }
    delete_users_worksites(where: { profile: $profilesConds }) @include(if: $updateWorksite) {
      affected_rows
    }
    insert_users_worksites(objects: $userworksites) @include(if: $updateWorksite) {
      affected_rows
    }
  }
`;

export const UPDATE_USER_BY_PK = gql`
  mutation UpdateUser($id: uuid = "", $userData: users_set_input = {}, $profileData: profiles_set_input = {}) {
    updateUser(pk_columns: { id: $id }, _set: $userData) {
      id
    }
    update_profiles_by_pk(pk_columns: { uuid: $id }, _set: $profileData) {
      uuid
    }
  }
`;

export const DELETE_PROFILE = gql`
  mutation DeleteProfile($uuid: uuid = "") {
    deleteUser(id: $uuid) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  query createUser(
    $company_id: String = ""
    $email: String = ""
    $firstname: String = ""
    $lastname: String = ""
    $phone_number: String = ""
    $role: String = ""
    $status: String = ""
    $pincode: String = ""
    $worksite_ids: [String!] = ""
    $company_serial: String! = ""
    $avatar_id: String! = ""
  ) {
    create_user(
      company_id: $company_id
      email: $email
      firstname: $firstname
      lastname: $lastname
      phone_number: $phone_number
      role: $role
      status: $status
      pincode: $pincode
      worksite_ids: $worksite_ids
      company_serial: $company_serial
      avatar_id: $avatar_id
    )
  }
`;

export const VALIDATE_SIGN_UP_FORM = gql`
  query ValidateSignUpForm($userData: UserCompany) {
    validate_sign_up_form(userData: $userData)
  }
`;

export const QUERY_GET_USERS = gql`
  query GetUsers($where: users_bool_exp = {}, $order_by: [users_order_by!] = {}) {
    users(where: $where) {
      id
    }
  }
`;

export const IS_PHONE_AVAILABLE = gql`
  query isPhoneAvailable($args: isPhoneNumberAvailable_arguments = {}) {
    isPhoneNumberAvailable(args: $args) {
      result
    }
  }
`;

export const IS_EMAIL_AVAILABLE = gql`
  query isEmailAvailable($args: isEmailAvailable_arguments = {}) {
    isEmailAvailable(args: $args) {
      result
    }
  }
`;

export const SUBSCRIBE_COMPANY_USER_BY_ROLE = gql`
  subscription GetCompanyUserByRole($companyId: uuid = "", $roles: [String!] = "") {
    profiles(where: { _and: { company_id: { _eq: $companyId }, user: { defaultRole: { _in: $roles } } } }) {
      uuid
      user {
        id
        email
        defaultRole
      }
    }
  }
`;

export const GET_EMAIL_ALERTS_USER = gql`
  query GET_EMAIL_CONDITIONS_USER($uuid: uuid = "", $newAccount: Boolean!, $checklist: Boolean!) {
    profiles_by_pk(uuid: $uuid) {
      email_when_new_account @include(if: $newAccount)
      email_when_checklist_fail @include(if: $checklist)
      email_when_checklist_warning @include(if: $checklist)
      email_when_weekly_report
    }
  }
`;

export const UPDATE_EMAIL_ALERTS_USER = gql`
  mutation UPDATE_EMAIL_ALERTS($uuid: uuid = "", $set: profiles_set_input = {}) {
    update_profiles_by_pk(_set: $set, pk_columns: { uuid: $uuid }) {
      uuid
    }
  }
`;

export const VERIFY_SIGN_UP_CODE = gql`
  query VerifySignUpCode($userData: UserCompany!, $code: String!) {
    verify_sign_up_code(userData: $userData, code: $code) {
      company_created
      name_available
      companies_for_merge {
        id
        name
      }
    }
  }
`;

export const SIGN_UP_IN_EXISTING_COMPANY = gql`
  query SignUpInExistingCompany($userData: UserCompany!, $code: String!, $companyId: String!) {
    sign_up_in_existing_company(userData: $userData, code: $code, company_id: $companyId)
  }
`;

export const SIGN_UP_WITH_NEW_COMPANY = gql`
  query SignUpWithNewCompany($userData: UserCompany!, $code: String!) {
    sign_up_with_company(userData: $userData, code: $code)
  }
`;

export const ACCEPT_USER_TOS = gql`
  mutation acceptTos {
    accept_tos
  }
`;

export const SUBSCRIBE_NEW_USER_NEEDS_APPROVAL = gql`
  subscription ProfilesNeedToBeApprovedAggregate {
    profiles_aggregate(where: { status: { _eq: "TO_BE_APPROVED" } }) {
      aggregate {
        count
      }
    }
  }
`;
