<template>
  <!-- Simple list item -->
  <v-list-item
    :id="route.id"
    @click="navigate(route.to)"
    class="navbar-item rounded-lg"
    :active="route.to === currentRoute.path"
    :class="rail ? 'pl-0' : 'pl-8'"
    :value="route.id"
    v-if="!route?.children"
  >
    <template v-slot:prepend>
      <v-img :src="`/images/drawer/${route.icon}.svg`" alt="Logo" :width="imgSize" class="mr-3 overflow-visible">
        <v-icon icon="mdi-circle" color="red" size="10" class="red-dot-image" v-if="route.badge"/>
      </v-img>
    </template>

    <template v-slot:title>
      <p class="navbar-item-text" :class="route.to === currentRoute.path ? 'text-white' : ''">
        {{ route.title }}
      </p>
    </template>
  </v-list-item>

  <!-- List item with multiple subitems -->
  <v-list-group :value="route.id" v-else-if="route.children.length > 0" :id="route.id">
    <template v-slot:activator="{ props, isOpen }">
      <v-list-item v-bind="props" class="navbar-item v-list-header rounded-lg" :class="rail ? 'pl-0' : 'pl-2'">
        <template v-slot:prepend>
          <div class="d-flex flex-row align-center">
            <v-icon :icon="isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right'" v-if="!rail" />
            <div class="active" />
            <v-img :src="`/images/drawer/${route.icon}.svg`" alt="Logo" :width="imgSize" class="mr-3 overflow-visible">
              <v-icon icon="mdi-circle" color="red" size="10" class="red-dot-image" v-if="route.badge || route.children.some((child) => child.badge)"/>
            </v-img>
          </div>
        </template>

        <template v-slot:title>
          <p class="navbar-item-text">
            {{ route.title }}
          </p>
        </template>

        <template v-slot:append> </template>
      </v-list-item>
    </template>

    <v-list-item
      v-for="subRoute of route.children"
      :id="subRoute.id"
      :key="subRoute.id"
      :value="subRoute.title"
      class="navbar-item rounded-lg overflow-visible"
      @click="navigate(subRoute.to)"
      :active="subRoute.to === currentRoute.path"
    >
      <template v-slot:title class="overflow-visible">
        <div class="navbar-item-text position-relative" style="">
          <p>{{ subRoute.title }}</p>
          <v-icon icon="mdi-circle" color="red" size="10" class="red-dot" v-if="subRoute.badge"/>
        </div>
      </template>
    </v-list-item>
  </v-list-group>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { Route } from "@/services/common-service/models";

defineProps<{
  route: Route;
  rail: boolean;
}>();

const router = useRouter();
const currentRoute = useRoute();
const navigate = (to: string) => router.push(to);
const imgSize = 25;
</script>

<style scoped lang="scss">
.v-list-group--open {
  .v-list-header {
    background-color: rgb(var(--v-theme-primary-500));
  }
  .navbar-item .navbar-item-text {
    color: white;
    width: fit-content;
  }

  .active {
    width: auto;
    margin-inline: 6px;
    border-left: solid;
    border-color: rgba(var(--v-theme-tertiary-900), 1);
    border-radius: 25px;
  }

  .active::after {
    content: " ";
    display: inline-block;
  }
}

.navbar-item {
  padding: calc(var(--v-base-spacing) * 1px) calc(var(--v-base-spacing) * 3px);
  border-radius: calc(var(--v-border-radius-root) * 1px);

  .navbar-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    white-space: normal;

    color: rgba(var(--v-theme-primary-100), 0.7);
  }

  &:hover {
    background-color: rgb(var(--v-theme-primary-500));
    cursor: pointer;
  }
}

.red-dot {
  position: absolute;
  right: -15px;
  top: 0px;
}

.red-dot-image {
  position: absolute;
  right: -5px;
  top: -5px;
}

.v-list-item__content {
  overflow: visible !important;
}

:deep(.v-list-item__content) {
  overflow: visible !important;
}

:deep(.v-list-item-title) {
  overflow: visible !important;
}
</style>
