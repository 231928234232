<template>
  <!-- Case when drawer is not railed -->
  <div v-if="!rail">
    <div
      class="d-flex flex-row clickable py-4"
      @click="menuOpened = !menuOpened"
    >
      <div :class="rail ? 'pl-1' : ''">
        <v-img
          :src="`/images/lang/${selected.key}.svg`"
          alt="Logo"
          :width="iconSize"
          class="mr-3"
        />
      </div>
      <p v-if="!rail">{{ selected.label }}</p>
      <v-icon
        v-if="!rail"
        :icon="menuOpened ? 'mdi-chevron-down' : 'mdi-chevron-right'"
      />
    </div>

    <div v-if="menuOpened" class="mt-1">
      <div v-for="language of availableLanguages" :key="language.key">
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex">
            <v-img
              :src="`/images/lang/${language.key}.svg`"
              alt="Logo"
              :width="iconSize"
              class="mr-3"
            />
            <p>{{ language.label }}</p>
          </div>

          <div>
            <v-checkbox
              color="tertiary-900"
              v-model="selected.key"
              true-icon="mdi-radiobox-marked"
              false-icon="mdi-radiobox-blank"
              :value="language.key"
              hide-details
              density="compact"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Case when drawer is railed -->
  <v-menu location="end" v-else>
    <template v-slot:activator="{ props, isActive }">
      <v-img
        :src="`/images/lang/${selected.key}.svg`"
        alt="Logo"
        :width="20"
        class="my-5 mx-1"
        v-bind="props"
      />
    </template>

    <v-list class="ml-4 bg-primary-500">
      <v-list-item
        v-for="language of availableLanguages"
        :key="language.key"
        :value="language.key"
        class="navbar-item"
        @click="selected.key = language.key"
      >
        <template v-slot:title>
          <p class="navbar-item-text">{{ language.label }}</p>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { AvailableLanguages, getLocal } from "@/locales/locales-models";
import { useI18n } from "vue-i18n";

const t = useI18n();

const availableLanguages = [
  { key: AvailableLanguages.EN, label: "English" },
  { key: AvailableLanguages.FR, label: "French" },
  { key: AvailableLanguages.ES, label: "Spanish" },
  { key: AvailableLanguages.DE, label: "German" },
];

const iconSize = 25;
const selected = ref({
  key: getLocal(),
  label: availableLanguages?.find((el) => el.key === getLocal()).label,
});
const menuOpened = ref(false);
defineProps<{
  rail: boolean;
}>();

watch(
  () => selected.value.key,
  (newValue) => {
    selected.value.label = availableLanguages.find(
      (el) => el.key === newValue
    ).label;
    menuOpened.value = false;
    t.locale.value = newValue;
    localStorage.setItem("i18n-locale", newValue);
  }
);
</script>

<style scoped>
.clickable:hover {
  cursor: pointer;
  background-color: rgba(var(--v-theme-primary-400), 0.7);
  border-radius: calc(var(--v-border-radius-root) * 1px);
}
</style>
