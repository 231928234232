function downloadFile(blob: Blob, fileName: string) {
    // Download the file
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    // Set the file name.
    link.download = fileName;
    link.click();
    link.remove();
}

export function exportToCSV(data: any[], columns: string[], name?: string) {
    const res = [columns, ...data].join("\n");

    const blob = new Blob([res], { type: "text/csv" });
    downloadFile(blob, name || "data.csv");
}
