<template>
  <v-app>
    <v-main class="bg-primary-900" v-if="base.isApplicationReady">
      <AppDrawer v-if="showDrawer" />
      <router-view :key="$route.path" v-slot="{ Component }" class="h-100 bg-primary-900">
        <v-slide-y-transition mode="out-in">
          <component :is="Component" class="overflow-y-auto" :class="componentClass" />
        </v-slide-y-transition>
      </router-view>
      <AppWhatsNew v-if="showWhatsNew" @acknowledge="acknowledgeWhatsNew" />
      <TosDialog v-model="tosDialog" @refuse="refuse()" @accept="accept()" />
    </v-main>
    <div v-else class="h-100 bg-primary-900 d-flex justify-center align-center">
      <v-progress-circular indeterminate size="100" color="tertiary-900"></v-progress-circular>
    </div>
    <Toastmanager />
  </v-app>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import AppDrawer from "@/components/templates/AppDrawer.vue";
import AppWhatsNew from "@/components/atoms/AppWhatsNew.vue";
import TosDialog from "@/components/molecules/auth/TosDialog.vue";

import { authClient } from "@/plugins/nhost";

import { useBaseStore } from "@/store/base";
import { useAuthStore } from "@/store/auth";
import { useAuthService } from "./services/auth-service";
import { useRoute, useRouter } from "vue-router";

import Toastmanager from "@/components/molecules/ToastManager.vue";
import { useSubscription } from "@vue/apollo-composable";
import { SUBSCRIBE_USER } from "./services/user-service/queries";
import { mapUser } from "./services/user-service/mapper";
import { useStorageService } from "./services/storage-service";
import { restartWs } from "./plugins/apollo";
import { acceptTos, UserRole } from "./services/user-service";
import { ToastType, useToastStore } from "./store/toast";
import { useI18n } from "vue-i18n";
import { checkCompanyFunctionnalities } from "./services/auth-service/utils";

const base = useBaseStore();
const auth = useAuthStore();
const toast = useToastStore();
const authService = useAuthService();
const route = useRoute();
const router = useRouter();
const storageService = useStorageService();
const componentClass = ref("");
const tosDialog = ref(false);
const { t } = useI18n();

const userId = ref(null);

const { onResult, onError, restart } = useSubscription(
  SUBSCRIBE_USER,
  () => ({
    uuid: userId.value,
    includeWorksite: false,
    includeTos: true,
  }),
  () => ({
    enabled: !!userId.value,
  })
);
const { loading, mutate: acceptTosMutate, onDone: onTosSuccess, onError: onTosError } = acceptTos();

onError(() => {
  auth.signOut();
  base.isApplicationReady = false;
});

onResult(async (result) => {
  if (result) {
    auth.user = mapUser(result.data.profiles_by_pk);
    tosDialog.value = !auth.user.tosAccepted;
    base.isApplicationReady = true;
  }
});

authClient.onAuthStateChanged(() => {
  authService.saveTokenFromSession();
  const session = authClient.getSession();

  if (session?.user?.id) {
    userId.value = session.user.id;

    if (
      ![
        UserRole.COMPANY_ADMIN.toString(),
        UserRole.APP_ADMIN.toString(),
        UserRole.COMPANY_ADMIN_DASHBOARD.toString(),
        UserRole.COMPANY_ADMIN_CHECKLIST.toString(),
        UserRole.SITE_MANAGER.toString(),
        UserRole.STANDARD_USER.toString(),
        UserRole.DRIVER.toString(),
        UserRole.INSTALLER.toString(),
        UserRole.LICENCE_MANAGER.toString(),
      ].includes(session.user.defaultRole)
    ) {
      toast.addToast(t("sign-in.not-allowed"), ToastType.ERROR);
      authService.signOut();
      userId.value = null;
    }
  } else {
    auth.user = null;
    userId.value = null;
    base.isApplicationReady = true;
  }
});

authClient.onTokenChanged((token) => {
  storageService.setToken(token?.accessToken);

  restartWs();
  restart();

  const session = authClient.getSession();

  if (session) {
    const companyFunctionalities = authClient.getHasuraClaim("company-functionalities");
    const canLog = checkCompanyFunctionnalities(companyFunctionalities as string[], session.user.defaultRole);

    if (!canLog) {
      toast.addToast(t("sign-in.not-allowed"), ToastType.ERROR);
      authService.signOut();
    }
  }
});

const acknowledgeWhatsNew = () => {
  storageService.setWhatsNewSeenContent(t("whatsnew.text"));
};

const showDrawer = computed(() => {
  return route.meta.hideDrawer != true && auth.user != null;
});

const showWhatsNew = computed(() => {
  const nowTimestamp = new Date().getTime() / 1000;
  const startTimestamp = Date.parse("2024-06-01T00:00:00") / 1000;
  const endTimestamp = Date.parse("2024-07-03T23:59:59") / 1000;
  const shouldOpen = nowTimestamp >= startTimestamp && nowTimestamp <= endTimestamp;
  const lastSeenWhatsNewContent = storageService.getWhatsNewSeenContent();
  return import.meta.env.VITE_SHOW_WHATS_NEW !== "false" && auth.user != null && shouldOpen && lastSeenWhatsNewContent !== t("whatsnew.text");
});

function refuse() {
  router.push("/sign-in");
  auth.signOut();
}

function accept() {
  acceptTosMutate();
}

onTosSuccess(() => {
  toast.addToast(t("tos.tos-updated"), ToastType.SUCCESS);
  tosDialog.value = false;
});

onTosError((e) => {
  toast.addToast(t("tos.tos-updated-error"), ToastType.ERROR);
});
</script>

<style scoped>
main {
  width: 100%;
  height: available;
}
</style>
