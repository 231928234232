import { mapWorksites } from "../worksite-service/mapper";
import { ProfileEmailAlert, User } from "./models";

export function mapUser(data: any): User {
  return new User(
    data?.uuid,
    data?.status,
    data?.firstname,
    data?.lastname,
    data?.company_serial,
    data?.user?.email,
    data?.user?.phoneNumber,
    data?.user?.defaultRole,
    data?.company?.name,
    data?.company_id,
    data?.avatar?.id,
    data?.avatar?.name,
    data?.pincode,
    data?.user?.lastSeen,
    data?.tos_accepted || false,
    mapWorksites({ worksites: data?.worksites?.map((el) => el.worksite) || [] })
  );
}

export function mapUsers(data?: any[]): User[] {
  return data ? data.map(mapUser) : [];
}

export function mapProfileEmailAlert(data?: any): ProfileEmailAlert {
  return new ProfileEmailAlert(
    data?.email_when_checklist_fail,
    data?.email_when_checklist_warning,
    data?.email_when_new_account,
    data?.email_when_weekly_report
  );
}
