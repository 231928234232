import { ComputedRef, computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { provideApolloClient, useMutation, useQuery, useSubscription } from "@vue/apollo-composable";
import { CompanyAvailabilityResult, ProfileData, ProfileEmailAlert, SignUpFormObject, User, UserData, UserForm } from "./models";
import { UserStatus, UserRole, ExtraUserStatus } from "./models";
import {
  DELETE_PROFILE,
  VALIDATE_SIGN_UP_FORM,
  IS_EMAIL_AVAILABLE,
  IS_PHONE_AVAILABLE,
  SUBSCRIBE_COMPANY_USER_BY_ROLE,
  SUBSCRIBE_ALL_PROFILES,
  SUBSCRIBE_PROFILES_COUNT,
  SUBSCRIBE_USER,
  UPDATE_PROFILE_STATUS_BY_PK,
  UPDATE_USER,
  UPDATE_USER_BY_PK,
  QUERY_PROFILES_FOR_CSV_EXPORT,
  CREATE_USER,
  GET_USER,
  GET_EMAIL_ALERTS_USER,
  UPDATE_EMAIL_ALERTS_USER,
  VERIFY_SIGN_UP_CODE,
  SIGN_UP_IN_EXISTING_COMPANY,
  SIGN_UP_WITH_NEW_COMPANY,
  ACCEPT_USER_TOS,
  SUBSCRIBE_NEW_USER_NEEDS_APPROVAL,
} from "./queries";
import { mapProfileEmailAlert, mapUser, mapUsers } from "./mapper";
import { debouncedRef } from "@vueuse/core";
import { useCurrentApolloClient } from "@/plugins/apollo";
import { useAuthService } from "../auth-service";
import { useAuthStore } from "@/store/auth";
import { Company } from "../company-service";
import { exportToCSV } from "@/helpers/export-utils";
import { useRoute } from "vue-router";

provideApolloClient(useCurrentApolloClient());

function useRoleItems() {
  const auth = useAuthStore();
  const { t } = useI18n();

  if (auth.isAppAdmin()) {
    return Object.entries(UserRole).map(([key, value]) => ({
      title: t(`enum.user_role.${key}`),
      value,
    }));
  } else if (auth.isCompanyAdminChecklist() || auth.isCompanyAdminDashboard()) {
    return Object.entries(UserRole)
      .filter(([key, value]) => ![UserRole.APP_ADMIN, UserRole.LICENCE_MANAGER].includes(value))
      .map(([key, value]) => ({
        title: t(`enum.user_role.${key}`),
        value,
      }));
  } else if (auth.isSiteManager()) {
    return Object.entries(UserRole)
      .filter(([key, value]) => [UserRole.SITE_MANAGER, UserRole.STANDARD_USER, UserRole.LICENCE_MANAGER].includes(value))
      .map(([key, value]) => ({
        title: t(`enum.user_role.${key}`),
        value,
      }));
  }

  return [];
}

function useStatusItems() {
  const { t } = useI18n();

  return Object.entries(UserStatus).map(([key, value]) => ({
    title: t(`enum.user_status.${key}`),
    value,
  }));
}

function subscribeAllUsers(opt: { defaultOrder?: {}; limit?: number }) {
  const textFilter = ref("");
  const columnOrders = ref(opt.defaultOrder);

  const selectedStatus = ref<UserStatus | null>(null);
  const selectedRole = ref<UserRole | null>(null);
  const selectedCompany = ref(null);

  const limit = ref(opt?.limit || 15);
  const page = ref(1);

  const statusFilter = computed(() =>
    selectedStatus.value
      ? {
          status: { _eq: selectedStatus.value },
        }
      : null
  );

  const rolesFilter = computed(() =>
    selectedRole.value
      ? {
          user: { defaultRole: { _eq: selectedRole.value } },
        }
      : null
  );

  const companiesFilter = computed(() =>
    selectedCompany.value
      ? {
          company_id: { _eq: selectedCompany.value },
        }
      : null
  );

  const textFilters = computed(() =>
    textFilter.value !== ""
      ? {
          _or: [{ firstname: { _ilike: `%${textFilter.value}%` } }, { lastname: { _ilike: `%${textFilter.value}%` } }, { user: { email: { _ilike: `%${textFilter.value}%` } } }],
        }
      : null
  );

  const allFilters = computed(() => [textFilters.value, rolesFilter.value, statusFilter.value, companiesFilter.value].filter(Boolean));

  const where = computed(() =>
    allFilters.value.length !== 0
      ? {
          _and: allFilters.value,
        }
      : {}
  );

  const offset = computed(() => (page.value - 1) * limit.value);

  watch(where, () => {
    if (page.value !== 1) {
      page.value = 1;
    }
  });

  const { result, loading: fetchLoading } = useSubscription(
    SUBSCRIBE_ALL_PROFILES,
    debouncedRef(
      ref({
        offset,
        limit,
        where: where,
        order_by: columnOrders,
      })
    )
  );

  const users: ComputedRef<User[]> = computed(() => mapUsers(result?.value?.profiles));

  const { result: totalResult, loading: totalLoading } = useQuery(SUBSCRIBE_PROFILES_COUNT, {
    where: debouncedRef(where),
  });
  const total = computed(() => totalResult.value?.profiles_aggregate.aggregate.count ?? 0);

  const loading = computed(() => fetchLoading.value);
  return {
    loading,
    columnOrders,
    textFilter,
    total,
    limit,
    page,
    users,
    selectedRole,
    selectedStatus,
    selectedCompany,
    exportToCsv: () => exportUsersCsv(where.value, columnOrders.value),
  };
}

function subscribeStandardAndSiteManagersUsers(initialCompanyId: string) {
  const companyFilter = ref(initialCompanyId || null);

  const { result, loading: fetchLoading } = useSubscription(
    SUBSCRIBE_COMPANY_USER_BY_ROLE,
    {
      companyId: companyFilter,
      roles: [UserRole.STANDARD_USER, UserRole.SITE_MANAGER],
    },
    () => ({
      enabled: Boolean(companyFilter.value),
    })
  );

  const users: ComputedRef<User[]> = computed(() => mapUsers(result?.value?.profiles));

  const loading = computed(() => fetchLoading.value);
  return {
    companyFilter,
    loading,
    worksiteManagers: computed(() => users.value.filter((el) => el.role === UserRole.SITE_MANAGER)),
    standardUsers: computed(() => users.value.filter((el) => el.role === UserRole.STANDARD_USER)),
  };
}

function exportUsersCsv(where: object, columnOrders: object) {
  const auth = useAuthStore();
  const exportWithWorksite = auth.isAppAdmin() || auth.isCompanyAdmin() || auth.isCompanyAdminDashboard();

  const { onResult: internalOnResult } = useQuery(QUERY_PROFILES_FOR_CSV_EXPORT(exportWithWorksite), {
    where: where,
    order_by: columnOrders,
  });

  function generateAndDownload(users: User[]) {
    const headers = ["userName", "lastSeen", "company", "pincode", ...(exportWithWorksite ? ["worksites"] : [])];

    const lines = users.map((report) => {
      return [report.fullName, report.lastSeen, report.companyName, report.pincode, ...(exportWithWorksite ? [`[${report.worksites.join(" / ")}]`] : [])].join(",");
    });

    exportToCSV(lines, headers, "users.csv");
  }

  internalOnResult((result) => {
    const users = mapUsers(result?.data?.profiles);
    generateAndDownload(users);
  });
}

async function updateUser(id: string, userData: UserData, profileData: ProfileData, updateUserWorksite: boolean = false) {
  // Prevent email validation & allow find via nest
  if (userData.email === "") userData.email = null;
  if (userData.phoneNumber === "") userData.phoneNumber = null;

  //Verify email not already used
  const emailIsAvailable = await isEmailAvailable(userData.email || "", id);
  if (!emailIsAvailable) {
    throw {
      status: 409,
      message: "Email already in use",
      error: "email-already-in-use",
    };
  }

  //Verify phone not already used
  if (userData.phoneNumber) {
    const phoneIsAvailable = await isPhoneAvailable(userData.phoneNumber, id);

    if (!phoneIsAvailable) {
      throw {
        status: 499,
        message: "Phone already in use",
        error: "phone-already-in-use",
      };
    }
  }

  const conditions = {
    id: {
      _eq: id,
    },
  };

  await useCurrentApolloClient().mutate({
    mutation: UPDATE_USER,
    variables: {
      usersConds: conditions,
      profilesConds: {
        user: conditions,
      },
      userData: {
        email: userData.email,
        defaultRole: userData.defaultRole,
        phoneNumber: userData.phoneNumber,
      },
      profileData: {
        firstname: profileData.firstName,
        lastname: profileData.lastName,
        avatar_id: profileData.avatarId,
        company_serial: profileData.companySerial,
        status: profileData.status,
        pincode: profileData.pincode,
      },
      userworksites: profileData?.worksites?.map((worksite) => {
        return {
          profil_id: id,
          worksite_id: worksite,
        };
      }),
      updateWorksite: updateUserWorksite,
    },
  });
}

async function isPhoneAvailable(phoneNumber: string, id?: string): Promise<boolean> {
  const res = await useCurrentApolloClient().query({
    query: IS_PHONE_AVAILABLE,
    variables: {
      args: {
        phone: phoneNumber,
        ...(id && {
          id: id,
        }),
      },
    },
  });
  if (res.errors) {
    throw Error(res.errors[0].message);
  }
  return res.data.isPhoneNumberAvailable[0].result;
}

async function isEmailAvailable(email: string, id?: string): Promise<boolean> {
  const res = await useCurrentApolloClient().query({
    query: IS_EMAIL_AVAILABLE,
    variables: {
      args: {
        email: email,
        ...(id && {
          id: id,
        }),
      },
    },
  });
  if (res.errors) {
    throw Error(res.errors[0].message);
  }
  return res.data.isEmailAvailable[0].result;
}

async function insertUser(userData: UserData, profileData: ProfileData) {
  // Prevent email validation & allow find via nest
  if (userData.email === "") userData.email = null;
  if (userData.phoneNumber === "") userData.phoneNumber = null;

  //On vérifie si un utiliateur a déjà ce numéro de téléphone
  if (userData.phoneNumber) {
    const phoneAlreadyUsed = await isPhoneAvailable(userData.phoneNumber);

    if (!phoneAlreadyUsed) {
      throw {
        status: 499,
        message: "Phone already in use",
        error: "phone-already-in-use",
      };
    }
  }

  if (userData.email) {
    const emailAlreadyUsed = await isEmailAvailable(userData.email);

    if (!emailAlreadyUsed) {
      throw {
        status: 409,
        message: "Email already in use",
        error: "email-already-in-use",
      };
    }
  }

  try {
    const res = await useCurrentApolloClient().query({
      query: CREATE_USER,
      variables: {
        company_id: profileData?.companyId || null,
        email: userData.email,
        firstname: profileData.firstName,
        lastname: profileData.lastName,
        phone_number: userData?.phoneNumber || null,
        role: userData.defaultRole,
        status: profileData.status || UserStatus.TO_BE_APPROVED,
        pincode: profileData.pincode,
        worksite_ids: profileData?.worksites || [],
        company_serial: profileData.companySerial,
        avatar_id: profileData?.avatarId || null,
      },
    });
    if (res.errors) {
      throw Error();
    }
  } catch (e: any) {
    throw {
      error: e.message,
    };
  }
}

async function validateSignUpForm(data: SignUpFormObject): Promise<Boolean> {
  const res = await useCurrentApolloClient().query({
    query: VALIDATE_SIGN_UP_FORM,
    variables: {
      userData: {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        company_name: data.companyName,
        company_address: data.companyAddress,
        phone_number: data.phoneNumber,
        request_code: data.requestCode,
      },
    },
  });

  if (res.errors) {
    throw Error(res.errors[0].message);
  }

  return res.data?.validate_sign_up_form;
}

async function verifySignUpCode(data: SignUpFormObject): Promise<CompanyAvailabilityResult> {
  const res = await useCurrentApolloClient().query({
    query: VERIFY_SIGN_UP_CODE,
    variables: {
      userData: {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        company_name: data.companyName,
        company_address: data.companyAddress,
        phone_number: data.phoneNumber,
        request_code: data.requestCode,
      },
      code: data.code,
    },
  });

  if (res.errors) {
    throw Error(res.errors[0].message);
  }

  return CompanyAvailabilityResult.map(res.data?.verify_sign_up_code);
}

async function signUpInExistingCompany(data: SignUpFormObject, companyId: string): Promise<Boolean> {
  const res = await useCurrentApolloClient().query({
    query: SIGN_UP_IN_EXISTING_COMPANY,
    variables: {
      userData: {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        company_name: data.companyName,
        company_address: data.companyAddress,
        phone_number: data.phoneNumber,
        request_code: data.requestCode,
      },
      code: data.code,
      companyId,
    },
  });

  if (res.errors) {
    throw Error(res.errors[0].message);
  }

  return res.data?.verify_sign_up_code;
}

async function signUpWithNewCompany(data: SignUpFormObject): Promise<Boolean> {
  const res = await useCurrentApolloClient().query({
    query: SIGN_UP_WITH_NEW_COMPANY,
    variables: {
      userData: {
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        company_name: data.companyName,
        company_address: data.companyAddress,
        phone_number: data.phoneNumber,
        request_code: data.requestCode,
      },
      code: data.code,
    },
  });

  if (res.errors) {
    throw Error(res.errors[0].message);
  }

  return res.data?.verify_sign_up_code;
}

function updateUserStatus(uuid: string, status: UserStatus) {
  const { mutate, onError, onDone } = useMutation(UPDATE_PROFILE_STATUS_BY_PK, {
    variables: {
      uuid: uuid,
      status: status,
    },
  });

  mutate();

  return {
    onError,
    onDone,
  };
}

function deleteUser(uuid: string) {
  const { mutate, onError, onDone } = useMutation(DELETE_PROFILE, {
    variables: {
      uuid,
    },
  });
  mutate();

  return {
    onError,
    onDone,
  };
}

function fetchUser(uuid: string, withWorksites: boolean = true) {
  const { result, loading } = useQuery(SUBSCRIBE_USER, {
    uuid,
    includeWorksite: withWorksites,
  });

  const user = computed<User>(() => {
    if (result.value) {
      return mapUser(result.value.profiles_by_pk);
    }

    return null;
  });

  return {
    loading,
    user,
  };
}

async function getUser(uuid: string) {
  const res = await useCurrentApolloClient().query({
    query: GET_USER,
    variables: {
      uuid,
    },
  });
  return mapUser(res.data.profiles_by_pk);
}

function askForDeletion(id: string) {
  return useCurrentApolloClient().mutate({
    mutation: UPDATE_USER_BY_PK,
    variables: {
      id: id,
      profileData: {
        status: ExtraUserStatus.ASKED_FOR_DELETION,
      },
    },
  });
}

async function getEmailAlerts(user: User): Promise<ProfileEmailAlert> {
  const auth = useAuthStore();
  const res = await useCurrentApolloClient().query({
    query: GET_EMAIL_ALERTS_USER,
    variables: {
      uuid: user.id,
      newAccount: auth.isAppAdmin(),
      checklist: auth.isCompanyAdminChecklist(),
    },
  });
  return mapProfileEmailAlert(res?.data?.profiles_by_pk);
}

async function updateEmailAlerts() {
  const { mutate, onError, onDone } = useMutation(UPDATE_EMAIL_ALERTS_USER);

  return {
    mutate,
    onError,
    onDone,
  };
}

function subscribeUserByRole(roles: UserRole[]) {
  const { result, loading: fetchLoading } = useSubscription(SUBSCRIBE_ALL_PROFILES, {
    where: { user: { defaultRole: { _in: roles } } },
  });

  const users: ComputedRef<User[]> = computed(() => mapUsers(result?.value?.profiles));

  const loading = computed(() => fetchLoading.value);
  return {
    loading,
    users,
  };
}

function acceptTos() {
  const { mutate, loading, onDone, onError } = useMutation(ACCEPT_USER_TOS);
  return {
    loading,
    mutate,
    onDone,
    onError,
  };
}

function subscribeNewUserNeedsApproval() {
  const auth = useAuthStore();
  const route = useRoute();

  const newResult = ref(false);
  const isFirstLoad = ref(true);
  const { onResult } = useQuery(SUBSCRIBE_NEW_USER_NEEDS_APPROVAL, {}, { enabled: auth.isAnyCompanyAdmin() });

  onResult(() => {
    if (isFirstLoad.value) {
      isFirstLoad.value = false;
      return;
    }
    newResult.value = true;
  });

  const hasNewUsersToApprove = computed(() => newResult.value);

  watch([() => hasNewUsersToApprove.value, () => route.path], () => {
    if (hasNewUsersToApprove.value && route.path === "/users") {
      newResult.value = false;
    }
  });

  return hasNewUsersToApprove;
}

export type { User, UserForm };

export {
  UserStatus,
  UserRole,
  subscribeAllUsers,
  updateUserStatus,
  fetchUser,
  useRoleItems,
  useStatusItems,
  deleteUser,
  updateUser,
  insertUser,
  askForDeletion,
  validateSignUpForm,
  subscribeStandardAndSiteManagersUsers,
  getUser,
  getEmailAlerts,
  updateEmailAlerts,
  subscribeUserByRole,
  verifySignUpCode,
  signUpInExistingCompany,
  signUpWithNewCompany,
  acceptTos,
  subscribeNewUserNeedsApproval,
};
